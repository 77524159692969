<template>
  <div class="list">
    <radio-button
      class="page-top"
      :type.sync="status"
      :btnList="[
        { label: `待回复(${leftList.length})`, value: '1' },
        { label: `咨询中(${rightList.length})`, value: '2' },
      ]"
      @change="tabChange"
    ></radio-button>
    <div
      class="page-top"
      v-if="(status === '1' && leftList.length) || (status === '2' && rightList.length)"
    >
      <div class="pd12">
        <patient-row
          v-for="item in (status === '1' ? leftList: rightList)"
          :key="item.id"
          :item="item"
        ></patient-row>
      </div>
    </div>
    <div v-else class="empty-data">
      <template v-if="status === '1'">
        暂无待回复的咨询，去看看咨询中的吧～～
      </template>
      <template v-else>
        暂无待回复咨询
      </template>
    </div>

    <!-- 刷新按钮 -->
    <div class="refresh">
      <a class="primary" @click="getData">刷新</a>
    </div>
  </div>
</template>

<script>
import radioButton from '@/components/radioButton.vue';
import patientRow from './components/patient-row.vue';

export default {
  components: { radioButton, patientRow },
  name: 'Consult',
  data() {
    return {
      status: this.$route.query.status || '1',
      leftList: [],
      rightList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    tabChange(status) {
      if (status === this.$route.query.status) return;
      this.$router.replace({
        path: this.$route.path,
        query: {
          status: this.status,
        },
      });
      this.getData();
    },
    getData() {
      this.$get(`/api/blade-consult/doctorWork/getWorkDetail`)
        .then(({ data }) => {
          this.consultData = data || {};
          const list = this.consultData.patientInfoList || [];
          this.leftList = list.filter(ll => ll.acceptsStatus === 1); // 待回复
          this.rightList = list.filter(ll => ll.acceptsStatus === 2); // 咨询中
        })
        .finally(() => {
          setTimeout(this.getData, 10 * 60 * 1000);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.list-container {
  max-height: calc(100vh - 64px);
  overflow: auto;
}
.refresh {
  position: fixed;
  top: 50%;
  right: 0;
  background-color: #fff;
  border-radius: 20px 0 0 20px;
  padding: 4px 12px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
</style>
