<template>
  <c-card class="row br6">
    <div class="header">
      <div class="header-item">
        <van-image
          v-if="item.avatar"
          :src="item.avatar"
          width="40"
          height="40"
          round
        ></van-image>
        <van-image
          v-else
          class="avatar"
          :src="item.sex == 1 ? require('@/assets/images/patient_m.png') : require('@/assets/images/patient_w.png')"
          width="40"
          height="40"
        ></van-image>
        <span class="ml4">{{ item.patientName }}</span>
        <span class="ml4">{{ item.sexName }}</span>
        <span class="ml4 mr12">{{ item.age }}岁</span>
        <van-tag type="primary" plain round v-if="item.acceptsStatus === 1">待回复</van-tag>
        <van-tag type="danger" plain round v-if="item.acceptsStatus === 2">咨询中</van-tag>
      </div>
      <div class="header-item">
      </div>
      <div class="header-item time">{{ item.createTime }}</div>
    </div>
    <div class="bottom">
      <div class="normal mb8">就诊号: {{ item.businessId }}</div>
      <div class="dark van-multi-ellipsis--l2">病情描述: {{ item.decription }}</div>
    </div>
    <template #footer>
      <div class="flex-between-center">
        <div class="primary" @click="toChatPage">
          {{ item.acceptsStatus == 1 ? '接诊' : '继续诊断' }}
        </div>
        <div class="success-color" @click="toPatientPage">查看患者信息</div>
      </div>
    </template>
  </c-card>
</template>

<script>
import cCard from '@/components/cCard.vue';

export default {
  components: { cCard },
  name: 'ConsultRow',
  props: {
    item: Object,
  },
  methods: {
    toChatPage() {
      this.$router.push(`/yisheng/chat?businessId=${this.item.businessId}`);
    },
    toPatientPage() {
      this.$router.push({
        path: '/yisheng/patient',
        query: {
          businessId: this.item.businessId,
        },
      });
    },
    showReject() {
      this.$dialog.alert({
        message: this.item.rejectedReason,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  .header-item {
    display: flex;
    align-items: center;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      margin-right: 2px;
      background: #f00;
    }
  }

  .time {
    color: #999;
    width: 90px;
    text-align: center;
  }
}
</style>
